import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CautionButton from './CautionButton';
import CautionBanner from './CautionBanner';

const Caution = ({ username, category, url, reasoncode, reason, zsq }) => (
  <Container as="main" className="main">
    <CautionBanner />
    <Container className="pt-4 pb-4">
      <Row className="justify-content-center">
        <div className="col-lg-8">
          <h2>Website Warning</h2>
          <p>
            {username === '' ? '' : 'Hi'}
            <strong className="text-capitalize">{username}</strong>You tried to visit <strong>{url}</strong>.
          </p>
          <p>
            This website is categorized as <strong>{category} </strong> and may contain dangerous content.
          </p>
          <p>
            Security policy: <strong>{reasoncode}</strong> is intended to remind you to exercise caution. Click the{' '}
            <strong>Continue to Accept Risk</strong> below if you would still like to visit the website. Otherwise, close the window.
          </p>
          <strong>Reason:</strong>
          <p>{reason}</p>
          <p>
            If you believe this category is not correct, you may request to change it by clicking <strong>Request Category Change</strong> below and
            following the prompts.
          </p>
          <p>
            Click&nbsp;
            <a
              href="https://thepantry--simpplr.visualforce.com/apex/simpplr__app?u=/site/a144W00000YKFwNQAX/page/a124W00000N525zQAB"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Read Technology Acceptable Use Policy
            </a>
            &nbsp;to learn more about our Policies. For any questions, concerns, or to report suspicious activity, please email{' '}
            <a href="mailto:informationsecurity@doma.com"> InformationSecurity@doma.com.</a>
          </p>
        </div>
      </Row>
    </Container>
    <CautionButton url={url} zsq={zsq} category={category} redirect={`https://sitereview.zscaler.com`} />
  </Container>
);

Caution.propTypes = {
  username: PropTypes.string,
  category: PropTypes.string,
  url: PropTypes.string,
  reasoncode: PropTypes.string,
  reason: PropTypes.string,
  zsq: PropTypes.string,
};

export default Caution;
