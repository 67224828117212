import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CategoryBlockButton from './CategoryBlockButton';
import BlockBanner from './BlockBanner';

const CategoryBlock = ({ username, category, url, reasoncode, reason }) => (
  <Container as="main" className="main">
    <BlockBanner />
    <Container className="pt-4 pb-4">
      <Row className="justify-content-center">
        <div className="col-lg-8">
          <h2>Website Blocked</h2>
          <p>
            {username === '' ? '' : 'Hi'}
            <strong className="text-capitalize">{username}</strong>Doma employees are not allowed to browse <strong>{category} </strong>
            categories on corporate owned devices.
          </p>
          <p>
            You tried to visit <strong>{url}</strong>, which was blocked by security policy: <strong>{reasoncode}</strong>
          </p>
          <strong>Reason:</strong>
          <p>{reason}</p>
          <p>
            If you believe this category is not correct, you may request to change it by clicking <strong>Request Category Change</strong> below and
            following the prompts.
          </p>
          <p>
            Click <strong>Read Technology Acceptable Use Policy</strong> to learn more about our policies.
          </p>
          <p>
            For any questions, concerns, or to report suspicious activity, please email{' '}
            <a href="mailto:informationsecurity@doma.com"> InformationSecurity@doma.com.</a>
          </p>
        </div>
      </Row>
    </Container>
    <CategoryBlockButton />
  </Container>
);

CategoryBlock.propTypes = {
  username: PropTypes.string,
  category: PropTypes.string,
  url: PropTypes.string,
  reasoncode: PropTypes.string,
  reason: PropTypes.string,
};

export default CategoryBlock;
