import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ReactComponent as Logo } from '../img/logo.svg';

const Header = () => (
  <Navbar>
    <Container className="container">
      <Row className="mt-4 mb-4">
        <a href="https://www.doma.com">
          <Logo alt="Doma Logo" height="50px" />
        </a>
      </Row>
      <div className="float-right mt-4">
        <a className="text-center" href="https://doma.service-now.com/sp" target="_blank" rel="noopener noreferrer nofollow">
          Get Help
        </a>
      </div>
    </Container>
  </Navbar>
);

export default Header;
