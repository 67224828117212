import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DLPButton from './DLPButton';
import BlockBanner from './BlockBanner';

const Block = ({ username, category, url, reasoncode, reason, zsq }) => (
  <Container as="main" className="main">
    <BlockBanner />
    <Container className="pt-4 pb-4">
      <Row className="justify-content-center">
        <div className="col-lg-8">
          <h2>Website Blocked</h2>
          <p>
            {username === '' ? '' : 'Hi'}
            <strong className="text-capitalize">{username}</strong>Doma employees are not allowed to share senstive data outside the company,
            including, Personal Identifiable Information/Non-public Information (PII/NPI).
          </p>
          <p>
            Sensitive information was idenfied on <strong>{url}</strong>, which was blocked by security policy: <strong>{reasoncode}</strong>
          </p>
          <strong>Reason:</strong>
          <p>{reason}</p>
          <p>
            If you believe this is not correct, or you have a valid business justification to share this data, please click on{' '}
            <strong>Open a Service Request</strong> below to send a service ticket to Information Security.
          </p>
          <p>
            Click <strong>Read Data Classification Policy</strong> to learn more about our policies.
          </p>
          <p>
            For any questions, concerns, or to report suspicious activity, please email{' '}
            <a href="mailto:informationsecurity@doma.com"> InformationSecurity@doma.com.</a>
          </p>
        </div>
      </Row>
    </Container>
    <DLPButton
      url={url}
      zsq={zsq}
      category={category}
      redirect={`mailto:InformationSecurity@doma.com?subject=DLP+Restriction&body=Please+Re-categorize+web-application:+${url}%0D%0ABusiness+Justification:+Enter+the+reason+here`}
    />
  </Container>
);

Block.propTypes = {
  username: PropTypes.string,
  category: PropTypes.string,
  url: PropTypes.string,
  reasoncode: PropTypes.string,
  reason: PropTypes.string,
  zsq: PropTypes.string,
};

export default Block;
