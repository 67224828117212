import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

const BlockButton = ({ redirect }) => (
  <Row className="justify-content-md-center">
    <div className="button button-icon mb-5">
      <a
        className="center-text"
        href="https://thepantry--simpplr.visualforce.com/apex/simpplr__app?u=/site/a144W00000YKFwNQAX/page/a124W00000N525zQAB"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Read Technology Acceptable Use Policy <i className="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </div>
    <div className="button button-icon mb-5">
      <a className="text-center" href={redirect} target="_blank" rel="noopener noreferrer nofollow">
        Open a Service Request <i className="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </div>
  </Row>
);

BlockButton.propTypes = {
  category: PropTypes.string,
  url: PropTypes.string,
  zsq: PropTypes.string,
  redirect: PropTypes.string,
};

export default BlockButton;
