import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';

const CautionButton = ({ url, zsq, category, redirect }) => (
  <Row className="justify-content-md-center">
    <div className="button button-icon justify-content-md-center mb-5 block text-center">
      <a href={`https://gateway.zscalertwo.net/_sm_ctn?_sm_url=${url}&_sm_rid=${zsq.slice(0, -3)}&_sm_cat=${category}`}>
        Continue to Accept Risk<i className="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </div>
    <div className="button button-icon mb-5">
      <a className="text-center" href={redirect} target="_blank" rel="noopener noreferrer nofollow">
        Request Category Change <i className="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </div>
  </Row>
);

CautionButton.propTypes = {
  category: PropTypes.string,
  url: PropTypes.string,
  zsq: PropTypes.string,
  redirect: PropTypes.string,
};

export default CautionButton;
