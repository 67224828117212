import React from 'react';
import Row from 'react-bootstrap/Row';

const CategoryBlockButton = () => (
  <Row className="justify-content-md-center">
    <div className="button button-icon mb-5">
      <a
        className="center-text"
        href="https://thepantry--simpplr.visualforce.com/apex/simpplr__app?u=/site/a144W00000YKFwNQAX/page/a124W00000N525zQAB"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Read Technology Acceptable Use Policy <i className="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </div>

    <div className="button button-icon mb-5">
      <a className="text-center" href="http://sitereview.zscaler.com" target="_blank" rel="noopener noreferrer nofollow">
        Request Category Change <i className="fa fa-angle-right" aria-hidden="true"></i>
      </a>
    </div>
  </Row>
);

export default CategoryBlockButton;
