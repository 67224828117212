import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

const BlockBanner = () => (
  <Container>
    <Row className="justify-content-center">
      <Alert variant="danger" className="text-center col-lg-10">
        <i className="fa fa-ban"></i> Sorry, this page is blocked for security reasons.
      </Alert>
    </Row>
  </Container>
);

export default BlockBanner;
