import React from 'react';
import Header from './components/Header';
import Caution from './components/Caution';
import Block from './components/Block';
import MCAS from './components/MCAS';
import FiletypeBlock from './components/FiletypeBlock';
import DLPBlock from './components/DLPBlock';
import CategoryBlock from './components/CategoryBlock';
import './components/App.css';

const params = new URLSearchParams(window.location.search);
const Action = params.get('action');
const Category = params.get('cat');
const Reason = params.get('reason');
const Reasoncode = params.get('reasoncode');
const URL = params.get('url');
const ZSQ = params.get('zsq');
let Username = params.get('user');

if (/.*@[a-z]*\.com/gi.test(Username)) {
  Username = ` ${Username.replace(/@[a-z]*\.com/gi, '').replace(/\./, ' ')}. `;
} else {
  Username = '';
}

const Main = () => {
  switch (Action === 'deny') {
    case Category === 'MCAS Unsanctioned Apps':
      return <MCAS action={Action} category={Category} reason={Reason} reasoncode={Reasoncode} url={URL} username={Username} zsq={ZSQ} />;
    case Reasoncode === 'METHOD_CAUTIONED':
      return <Caution action={Action} category={Category} reason={Reason} reasoncode={Reasoncode} url={URL} username={Username} zsq={ZSQ} />;
    case Reasoncode === 'FILETYPE_DENIED':
      return <FiletypeBlock action={Action} category={Category} reason={Reason} reasoncode={Reasoncode} url={URL} username={Username} zsq={ZSQ} />;
    case Reasoncode.includes('DLP_'):
      return <DLPBlock action={Action} category={Category} reason={Reason} reasoncode={Reasoncode} url={URL} username={Username} zsq={ZSQ} />;
    case Reasoncode === 'CATEGORY_DENIED':
      return <CategoryBlock action={Action} category={Category} reason={Reason} reasoncode={Reasoncode} url={URL} username={Username} zsq={ZSQ} />;
    default:
      return <Block action={Action} reason={Reason} reasoncode={Reasoncode} url={URL} username={Username} zsq={ZSQ} />;
  }
};

const App = () => (
  <>
    <Header />
    <Main />
  </>
);

export default App;
