import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

const CautionBanner = () => (
  <Container>
    <Row className="justify-content-center">
      <Alert variant="warning" className="text-center col-lg-10">
        <i className="fa fa-warning"></i> Are you sure you want to visit the site?
      </Alert>
    </Row>
  </Container>
);

export default CautionBanner;
